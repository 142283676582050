import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const testimonialData = [
    {
      testimonialThumb: '/images/testimonial_1.jpeg',
      testimonialText:
        'Working with Arino Technologies on our website was an exceptional experience. Their team demonstrated professionalism, creativity, and expertise throughout. The result surpassed our expectations, with a visually stunning design, exceptional user experience, and outstanding performance.',
      avatarName: 'Ahon Monsery',
      avatarDesignation: 'Synergetic Innovations',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/testimonial_2.jpeg',
      testimonialText:
        'Arino Technologies delivered an outstanding website for us. They created a visually stunning design, seamless user experience, and exceptional performance. Working with Arino Technologies was a pleasure, and we highly recommend their services.',
      avatarName: 'Ahon Monsery',
      avatarDesignation: 'Innovix Labs',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/testimonial_3.jpeg',
      testimonialText:
        'Arino Technologies crafted an exemplary mobile application for us, demonstrating unparalleled professionalism and creativity throughout. Working with Arino Technologies was a pleasure, and we wholeheartedly endorse their mobile app development services.',
      avatarName: 'Ahon Monsery',
      avatarDesignation: 'QuantumLeap Ventures',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/testimonial_1.jpeg',
      testimonialText:
        "Arino Technologies has revolutionized our online presence with their React website development. Their team's ingenuity, dedication, and mastery of the React framework have brought our vision to life in ways we hadn't imagined. The website's dynamic features, fluid navigation, and impeccable design reflect Arino's commitment to excellence. Collaborating with Arino Technologies was an inspiring journey, and we eagerly anticipate future projects with their talented team.",
      avatarName: 'Ahon Monsery',
      avatarDesignation: 'NexGen Dynamics',
      ratings: '5',
    },
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
