import React, { useEffect,useState } from 'react';
import Div from '../Div'

export default function Newsletter({title, subtitle, placeholder}) {


  const [formData, setFormData] = useState({
    subscriptionEmail: '',
  });

  const handleSubmit2 = (e) => {
    e.preventDefault();
    // You can perform form validation here if needed
    console.log("formData",formData)
    console.log("email",formData.subscriptionEmail)

    var baseurl = "https://sendcontactemail-kmh3su4poa-uc.a.run.app"
    // Submit the form data to your backend API endpoint
    fetch(`${baseurl}?email=${formData.subscriptionEmail}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (response.ok) {
        // Handle successful form submission
        alert('Form submitted successfully');
        setFormData({
          subscriptionEmail: '',
        });
      } else {
        // Handle errors
        alert('Failed to submit form');
      }
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred');
    });
  };



  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
        <form onSubmit={handleSubmit2} className="cs-newsletter_form">
          <input type="email" name="subscriptionEmail" className="cs-newsletter_input" placeholder={placeholder} />
          <button className="cs-newsletter_btn"><span>Send</span></button>
        </form>
        <Div className="cs-newsletter_text">{subtitle}</Div>
      </Div>
    </>
  )
}
