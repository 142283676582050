import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'Do you design illustration website?',
      answer: 'Yes, we specialize in creating custom illustration websites that reflect your brands unique identity. Whether you need something artistic, modern, or minimalist, we craft visually stunning sites tailored to your vision.'
    },
    {
      question: 'Do you provide design source file after finish work?',
      answer: 'Absolutely! Once the project is complete, we deliver all the necessary design source files, including AI, PSD, or any other formats you may require, ensuring you have full access and control over your assets.'
    },
    {
      question: 'How to provide project details and payments?',
      answer: 'You can provide project details via our project form on the website or directly by contacting us through email. Payments can be made through a secure online payment gateway, which we will guide you through once the project is confirmed.'
    },
    {
      question: 'Can you tell me please how to contact for project?',
      answer: 'You can easily contact us by filling out the inquiry form on our website, sending us an email, or giving us a call. We’re here to discuss your project needs and help bring your vision to life!'
    },
    {
      question: 'Do you makes custom logo, icon etc?',
      answer: 'Yes, we design custom logos, icons, and other branding materials tailored to your specific requirements. Our designs are unique and align perfectly with your brands identity.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
